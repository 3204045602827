import * as React from 'react';

import { graphql } from 'gatsby';

import CampaignDetailsModule from '../../modules/campaignDetails';
import { Typography } from '@mui/material';

const CampaignDetailsPage = (props: any) => {
  return (
    <CampaignDetailsModule campaignKey="donation-batteries" showDonationDescription={false}>

      <Typography paragraph={true}>
        A couple of friends defending the freedom of Ukraine and Europe on a battlefield in Eastern Ukraine have asked us for support.
        Urgently needed are 20x batteries for portable digital radios which can be purchased in Germany. Price per battery unit is 94€.
        We will collect the required amount, if 100 people will donate as little as just 20€.
      </Typography>

      <Typography paragraph={true}>
        If you would like to donate any amount of money for the right cause or would like to purchase a portable radio battery by yourself
        and ship it to us, it will be a great Christmas present for those defending our European democracy.
      </Typography>

      <Typography paragraph={true}>
        The purchased batteries will be delivered afterwards by our persons of trust directly to the place where they are the most needed
        now. I will provide a final report about the collected donations and delivery status.
      </Typography>

      <Typography paragraph={true}>
        For any questions, please contact Sergej Andrushchenko via <a href="mailto:sergi.andruschenko@gmail.com">sergi.andruschenko@gmail.com</a>
      </Typography>

    </CampaignDetailsModule>
  );
};

export default CampaignDetailsPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
